.element {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.tableCol {
  display: grid;
}

.affiPop {
  text-align: center;
}

.etabl button {
  border-radius: 28px !important;
  background-color: #f5a624 !important;

  border-color: #f5a624 !important;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
  font-size: 15px !important;
  margin-bottom: 10%;
  border-width: 0px;
  margin-top: 10%;
  padding-right: 3%;
  padding-left: 3%;

  color: white;
}
