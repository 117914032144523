body {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.containerTitle {
  background-color: #f5a624;
  color: white;
  padding: 30px;
}

h2 {
  text-align: center;
  padding-right: 3%;
}

.title {
  text-align: center;
  color: "white";
}

.rowBox {
  display: flex;
  gap: 2;
  align-items: center;
}

.element {
  text-align: center;
  gap: 2;
  flex-grow: 2;
}
