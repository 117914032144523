.rowCenterWeb {
  justify-content: center;
  text-align: center;
}

.backgroundTicketVisibleWeb {
  justify-content: center;
  background-color: #f5a624;
  margin-bottom: 3%;
  padding-top: 8%;
}

.borderCol {
  background-color: white;
  padding: 3%;
  margin-left: 28%;
  margin-right: 28%;
}

.rowMargin {
  margin-top: 5%;
}

.text1 {
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  font-size: 21px;
}

.textDeux {
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  font-size: 21px;
}

.text3 {
  font-family: "Barlow Condensed", sans-serif;
  color: #555;
  font-size: 21px;
}

.titleName {
  color: #555;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 21px;
  text-align: center;
}

.colThanks {
  margin-bottom: 5%;
}

.text4Ticket {
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  font-size: 21px;
  padding-bottom: 3%;
}

.colorRow {
  background-color: white;
  padding-top: 5%;
}

.colorRow img {
  width: 90%;
}

.colorRowPrint img {
  margin-top: 10px;
  width: 90%;
}

/* web carte*/
.backgroundTicketVisibleWebCarte {
  justify-content: center;
  background-color: #f5a624;
  margin-bottom: 3%;
  padding-top: 8%;
  padding-bottom: 3%;
}

.rowMarginCard {
  margin-bottom: 10%;
}

.textDeuxMenu {
  font-family: "Barlow Condensed", sans-serif;
  color: #555;
  font-size: 21px;
}

.text3Menu {
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  font-size: 21px;
}
/* print */

.QRPrint {
  display: none;
}

.backgroundTicket {
  background-color: #f5a624;
  width: 45mm;
  height: 70mm;
  margin-top: 2%;
  margin-left: 2%;
  padding-top: 1%;
}

.borderColPrint {
  justify-content: center;
  padding-top: 4%;
  margin-bottom: 4%;
  margin-left: 12%;
  margin-right: 12%;
  width: 35mm;
  background-color: white;
}

.colCenter {
  text-align: center;
}

.colMarginWord {
  margin-top: -3%;
}

.textDeuxPrint {
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  font-size: 10px;
}

.colMarginRap {
  margin-bottom: 3%;
}
.text1Print {
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  font-size: 15px;
  text-align: center;
}

.text3Print {
  font-family: "Barlow Condensed", sans-serif;
  color: #555;
  font-size: 10px;
}

.titleNamePrint {
  color: #555;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 20px;
  text-align: center;
}

.colorRowPrint {
  background-color: white;
  margin-top: 15%;
}

.text4 {
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  font-size: 10px;
}

/* print carte */

.text1PrintMenu {
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  font-size: 10px;
  text-align: center;
}

.textDeuxPrintMenu {
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  font-size: 10px;
}

.text3PrintMenu {
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  font-size: 10px;
}

.text3PrintMenuGrey {
  font-family: "Barlow Condensed", sans-serif;
  color: #555;
  font-size: 10px;
}

.titleNameMenu {
  color: #555;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 10px;
  text-align: center;
}
