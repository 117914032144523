.element {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.tableCol {
  display: grid;
}

.affiPop {
  text-align: center;
}
